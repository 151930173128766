import Rails from "rails-ujs";

// Base components
import "init";
import "components/page/page";
import "components/not-found/not-found";
// Footer
import "components/footer/footer";
// Signin and singup pages components
import "components/auth-form/auth-form";
import "components/registration-form/registration-form";
import "components/reset-form/reset-form";
import "components/change-password-form/change-password-form";
import "components/confirmation-form/confirmation-form";
import "components/reactivation-form/reactivation-form";
//
import "components/domain-form/domain-form";
// User pages components
import "components/users/users";
import "components/user-form/user-form";
// services, invoices & payments
import "components/services/services";
import "components/invoices/invoices";
import "components/invoice/invoice";
import "components/payments/payments";
// usages
import "components/usages/usages";
// TP
import "components/tariff-plans/tariff-plans";
import "components/tariff-plan-form/tariff-plan-form";
import "components/tariff-plan-checkboxes/tariff-plan-checkboxes";
import "components/tariff-plan-radio-buttons/tariff-plan-radio-buttons";
// permissions
import "components/permissions/permissions";

const images = require.context("../images", true);
const imagePath = name => images(name, true);

// const importAll = r => r.keys().map(r);
// importAll(require.context("../images", false, /\.(png|jpe?g|svg)$/));

Rails.start();
