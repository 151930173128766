import jquery from "jquery";
import "jquery-mask-plugin";
import "popper.js";
import "bootstrap";
import moment from "moment/moment";
import dayjs from "dayjs";
import "material-dashboard/assets/js/core/bootstrap-material-design.min";
import "material-dashboard/assets/js/plugins/bootstrap-notify";
import "material-dashboard/assets/js/plugins/chartist.min";
import "material-dashboard/assets/js/plugins/perfect-scrollbar.jquery.min";
import "material-dashboard/assets/js/material-dashboard";
import "bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker";
import "jquery-tags-input/dist/jquery.tagsinput.min";
import "select2/dist/js/select2.full";

import "./index.css";

window.jQuery = jquery;
window.$ = jquery;
window.dayjs = dayjs;
window.moment = moment;

// polyfill for support IE and Safari
if (!HTMLFormElement.prototype.reportValidity) {
  /* eslint-disable func-names */
  // eslint-disable-next-line prettier/prettier
  HTMLFormElement.prototype.reportValidity = function () {
    if (this.checkValidity()) return true;
    const btn = document.createElement("button");
    this.appendChild(btn);
    btn.click();
    this.removeChild(btn);
    return false;
  };
}
